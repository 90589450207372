import "../../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.15_@types+node@20.17.10_babel-plugin-macros@3.1.0_terser@_up4y6fjbw7lo554abzy63tgjke/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2Fsections%2Fsplash-section%2FSplashSection.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA7VUy26DMBC85yt8qZQcXJmHwZBL%2F6RywQVLvAouUFX59wrbJMaQkEuPXs%2FOzM5afk0FFV8Cgd8DACnvmoL%2BxCBreXo%2BAJDRJgY9bY8QvodBmfes707TRVPQhEEuWNnFIGGVYO1UFmwUkBY8q8xqQ9OUVxkUtc02DKfz4XJ4VSYcaUKjY4As5dbEuhI75Fww2DU0YTGo6qGljYHxJGbbU0lHOPBU5DGIEGrG89PKvnK5HYCmdBB6mY4541kupjPRGoauQ5TwlRkr5rrjgtdVDFpWUMF79iDwO3pBiBfMgcVMP7q6%2BBZsQeF6yuJMQlysCnJvEC8pQ0lZsE8RA%2BhakxB52Sqe1W2kdrcX1bZdZcYhS0r6xIDuvOdVSm8lSzkFXdIyVgFapeBo7skLcDOepMLiDdyYcKijutww2ML4nrvCBBqjlbDjKIjR5bhzSU6OLIZQM%2BhFYLTSIBoxb2MDEt0b57r7aNVDl9bDgKys3wbei9if1rlIGGn6xd%2Fhk39IcB7evNzzK7%2BMxy%2FC7YedB%2BEhfy9V7CN7HKNrz2Wwcmlb2HBpOSCRQhg9ztxz%2BQOfpGVBPQYAAA%3D%3D%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var containerCx = 'dtatqt0';
export var gradientCx = 'dtatqt9';
export var imageWrapperCx = 'dtatqta';
export var linesStyle = _7a468({defaultClassName:'dtatqt6',variantClassNames:{position:{left:'dtatqt7',right:'dtatqt8'}},defaultVariants:{},compoundVariants:[]});
export var noWrapCx = 'dtatqt2';
export var screenshotInnerWrapperCx = 'dtatqt5';
export var screenshotOuterWrapperCx = 'dtatqt4';
export var subCx = 'dtatqt3';
export var titleCx = 'dtatqt1';